import Image from "next/image";
import Link from "next/link";
import { FC, Suspense } from "react";

import { A_STREAM_LINED_PROCESS } from "../../StaticData/AStreamLinedProces";

import sectionFourImage from "../../../public/img/section-4-background-image-newest.png";
import mapAnimationRepeat from "../../../public/img/map-animation-repeat.gif";
import truckImg from "../../../public/img/trucks.jpg";
import FadeToColor from "../../UI/FadeToColor/FadeToColor";
import HorizontalTabs from "../../components/HorizontalTabs/HorizontalTabs";
import dynamic from "next/dynamic";
import Hero2023 from "../../components/heroBannerGarndOpen2023/heroGO2023";

const Hero = dynamic(() => import("../../components/Hero/Hero"));
const Counter = dynamic(() => import("../../components/Counter/Counter"));
const ReviewSlider = dynamic(
	() => import("../../components/ReviewSlider/ReviewSlider"),
);

const HomePage: FC = () => {
	return (
		<>
			<main className="page-holder homepage-holder">
				<Hero />
				<section className="section-five">
					<div className="container">
						{/* <div className="s5-hr-line"></div> */}
						<Counter />
					</div>
				</section>

				{/* <Hero2023 /> */}

				<section className="section-seven">
					<div className="container">
						<div className="content-holder">
							<div className="list-holder">
								<div className="glove-wrap">
									<h2 className="glove-title d-flex">
										<FadeToColor className="fadeToWhite">
											<p>Pledge</p>
										</FadeToColor>
										{/* <span className="blacktowhite">Pledge</span> */}
										<FadeToColor className="fadeToYellow">
											<p>&nbsp;Zero</p>
										</FadeToColor>
										{/* <span className="zero-glove">&nbsp;Zero</span> */}
									</h2>

									<p className="list-item">
										<span className="zero">ZERO</span> cherry-picking
									</p>
									<p className="list-item">
										<span className="zero">ZERO</span> insane mark-ups
									</p>
									<p className="list-item">
										<span className="zero">ZERO</span> misrepresented products
									</p>
									<p className="list-item">
										<span className="zero">ZERO</span> scamming
									</p>
									<p className="list-item">
										<span className="zero">ZERO</span> B.S.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section-three">
					<div className="container">
						<div className="titles-holder">
							<h5 className="minititle">A STREAMLINED PROCESS</h5>

							<h2 className="section-title  wow fadeInUp">
								{" "}
								We Make Wholesale Purchasing Simple From Start to Finish
							</h2>
						</div>

						<HorizontalTabs tabsData={A_STREAM_LINED_PROCESS} />

						<div className="button-holder">
							<Link href="/inventory">
								<a className="section-btn"> Get Started </a>
							</Link>
						</div>
					</div>
				</section>

				<section className="section-four">
					<div className="section-background-image image-animate-scale">
						<Image src={sectionFourImage} alt="Picture" layout="responsive" />
					</div>
					<div className="container">
						<div className="text-holder position-relative">
							<h2>
								High demand, <br />
								low cost,
								<br />
								top retailers.
							</h2>
						</div>
					</div>
				</section>

				<section className="section-six">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-12 firstCol">
								<div className="image-holder map-holder">
									<Image src={mapAnimationRepeat} alt="image" />
								</div>
							</div>
							<div className="col-lg-6 col-md-12 secondCol">
								<div className="text-holder">
									<h2 className="section-title wow fadeInUp">
										We Deliver Everywhere
									</h2>
									<p className="section-subtitle">
										Our team leverages industry-leading, proprietary software to
										offer you the most competitive freight rates, wherever you
										may be and for whatever you may need!
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section-two">
					<Image src={truckImg} alt="image" layout="fill" objectFit="cover" />
					<div className="container">
						<div className="titles-holder">
							<h2 className="section-title">
								We<span>&#39;</span>ve got you covered
							</h2>
							<p className="section-subtitle">
								Find the best truckloads, from top retailers at low, low costs.
							</p>
						</div>

						<div className="boxes-holder">
							{/*<div className="row">
								<div className="col-lg-4 col-md-6 wow fadeInUp">
									<div className="box">
										<div className="box-image-overlay"></div>
										<div className="image-holder">
											<img src="/img/section-2-box-image-1.png" alt="image" />
										</div>
										<div className="hover-text">
											<Link href="/">
												<a className="view-now">Truckloads</a>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 wow fadeInUp">
									<div className="box">
										<div className="box-image-overlay"></div>
										<div className="image-holder">
											<img src="/img/section-2-box2-newimage.png" alt="image" />
										</div>
										<div className="hover-text">
											<a href="#" className="view-now">
												Pallets
											</a>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 wow fadeInUp">
									<div className="box">
										<div className="box-image-overlay"></div>
										<div className="image-holder">
											<img src="/img/section-2-box-image-3.png" alt="image" />
										</div>
										<div className="hover-text">
											<Link href="/">
												<a className="view-now">Box Lots</a>
											</Link>
										</div>
									</div>
								</div>
							</div> */}

							<div className="button-holder">
								<Link href="/inventory">
									<a className="section-btn">View All Truckloads</a>
								</Link>
							</div>
						</div>
					</div>
				</section>

				<section className="section-nine">
					<div className="titles-holder">
						<h2 className="section-title wow fadeInUp">Build a Partnership</h2>
						<p className="section-subtitle">
							Finding a great partnership with the right person can have an
							incredible impact on your business success.
						</p>
					</div>

					<div className="button-holder">
						<Link href="/contact">
							<a className="section-btn">Contact Us</a>
						</Link>
					</div>
				</section>
				<ReviewSlider />
			</main>
		</>
	);
};

export default HomePage;
